import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

import { Switch } from "@mui/material";

export default function SettingsOverlay(props) {
  const { theme, toggleTheme, toggleShowSettings } = props;
  return (
    <div className="overlay">
      <div className="overlay-content">
        <FontAwesomeIcon
          icon={faXmark}
          className="icon xmark"
          onClick={toggleShowSettings}
        />
        <h2 style={{ fontWeight: "bold" }}>
          <Switch checked={theme === "dark"} onChange={toggleTheme} /> dark
          theme
        </h2>
        <a
          href="mailto:wordlockgame@gmail.com"
          style={{
            color: "var(--color-primary)",
            textDecoration: "none",
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faEnvelope} /> wordlockgame@gmail.com
          </h3>
        </a>
        <a
          href="https://twitter.com/wordlockgame"
          rel="noreferrer"
          target="_blank"
          style={{
            color: "var(--color-primary)",
            textDecoration: "none",
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faTwitter} /> @wordlockgame
          </h3>
        </a>
      </div>
    </div>
  );
}
