import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function ContactOverlay(props) {
  const { toggleShowContact } = props;
  return (
    <div className="overlay">
      <div className="overlay-content">
        <FontAwesomeIcon
          icon={faXmark}
          className="icon"
          style={{
            padding: "16px",
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          onClick={toggleShowContact}
        />
        <a
          href="mailto:wordlockgame@gmail.com"
          style={{
            color: "var(--color-primary)",
            textDecoration: "none",
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faEnvelope} /> wordlockgame@gmail.com
          </h3>
        </a>
        <a
          href="https://twitter.com/wordlockgame"
          rel="noreferrer"
          target="_blank"
          style={{
            color: "var(--color-primary)",
            textDecoration: "none",
          }}
        >
          <h3>
            <FontAwesomeIcon icon={faTwitter} /> @wordlockgame
          </h3>
        </a>
      </div>
    </div>
  );
}
