import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatsService from "./../../service/stats/StatsService";
import WordService from "../../service/word/WordService";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default class SolutionsOverlay extends Component {
  constructor(props) {
    super(props);
    this.wordService = new WordService();
    this.statsService = new StatsService();
  }

  state = {
    solutions: [],
  };

  componentDidMount() {
    const { puzzleKey } = this.props;
    const solutions = this.wordService.getSolutions(puzzleKey);
    this.setState({ solutions });
  }

  render() {
    const { toggleShowSolutions } = this.props;

    const { solutions } = this.state;

    solutions.sort(
      (a, b) =>
        this.statsService.getSolutionPoints(b) -
        this.statsService.getSolutionPoints(a)
    );

    return (
      <div className="overlay">
        <div className="overlay-content">
          <FontAwesomeIcon
            icon={faXmark}
            className="icon xmark"
            onClick={toggleShowSolutions}
          />
          <h2 style={{ fontSize: "20px" }}>{solutions.length} solutions</h2>
          <div style={{ overflow: "scroll", paddingBottom: "20px" }}>
            {solutions.map((solution, index) => {
              const points = this.statsService.getSolutionPoints(solution);
              return (
                <div key={index}>
                  <h2 style={{ fontSize: "20px" }}>
                    #{index + 1} -{" "}
                    <span style={{ color: "var(--text-secondary" }}>
                      {points} {points === 1 ? "pt" : "pts"}
                    </span>
                  </h2>
                  {solution.map((word) => (
                    <p
                      style={{ margin: 0, padding: 0, letterSpacing: 10 }}
                      key={index + word}
                    >
                      {word.toUpperCase()}{" "}
                      <span style={{ color: "var(--text-secondary" }}>
                        {this.statsService.getWordPoints(word)}
                      </span>
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
