import DateService from "../date/DateService";

const GAME_STATE = "GAME_STATE";
const REMOVED_WORDS = "REMOVED_WORDS";
const THEME = "THEME";

class CacheService {
  constructor() {
    this.dateService = new DateService();
  }

  isFirstVisit = () => {
    return localStorage.getItem(GAME_STATE) === null;
  };

  getGameState = () => {
    return JSON.parse(localStorage.getItem(GAME_STATE)) || {};
  };

  getRemovedWords = () => {
    return JSON.parse(localStorage.getItem(REMOVED_WORDS)) || {};
  };

  getGameStateForKey = (key) => {
    const gameState = JSON.parse(localStorage.getItem(GAME_STATE)) || {};
    return gameState[key] || [];
  };

  setGameStateForKey = (key, solution) => {
    const gameState = JSON.parse(localStorage.getItem(GAME_STATE)) || {};
    gameState[key] = solution;
    localStorage.setItem(GAME_STATE, JSON.stringify(gameState));
  };

  getRemovedWordsForKey = (key) => {
    const removedWords = JSON.parse(localStorage.getItem(REMOVED_WORDS)) || {};
    return removedWords[key] || 0;
  };

  incrementRemovedWordsForKey = (key) => {
    let removedWords = JSON.parse(localStorage.getItem(REMOVED_WORDS)) || {};
    const count = (removedWords[key] || 0) + 1;
    removedWords[key] = count;
    localStorage.setItem(REMOVED_WORDS, JSON.stringify(removedWords));
  };

  getTheme = () => {
    return JSON.parse(localStorage.getItem(THEME));
  };

  setTheme = (theme) => {
    localStorage.setItem(THEME, JSON.stringify(theme));
  };
}

export default CacheService;
