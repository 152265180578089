import {
  faTrashCan,
  faWandMagicSparkles,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function HelpOverlay(props) {
  const { toggleShowHelp } = props;
  return (
    <div className="overlay">
      <div className="overlay-content">
        <FontAwesomeIcon
          icon={faXmark}
          className="icon xmark"
          onClick={toggleShowHelp}
        />
        <h2 style={{ fontWeight: "bold" }}>how to play</h2>
        <p>play five words to solve the puzzle</p>
        <p>select one letter from each column to make a word and click play</p>
        <p>up to 5 points are assigned based on the rarity of the word</p>
        <p>
          press <FontAwesomeIcon icon={faTrashCan} /> to remove words
        </p>
        <p>
          press <FontAwesomeIcon icon={faWandMagicSparkles} /> to show hints
        </p>
      </div>
    </div>
  );
}
