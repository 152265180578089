export default function Tile(props) {
  const { char, col, played, selected, updateCurrentWord, animate } = props;

  const className = "tile";

  return (
    <div
      className={className}
      onClick={() => !played && updateCurrentWord(char, col)}
      style={{
        color: animate
          ? "green"
          : played
          ? "var(--text-disabled)"
          : "var(--text-primary)",
        borderColor: animate
          ? "green"
          : played
          ? "var(--text-disabled)"
          : "var(--text-primary)",
        backgroundColor: selected ? "var(--theme-color)" : "",
        cursor: played ? "not-allowed" : "pointer",
      }}
    >
      <span>{char}</span>
    </div>
  );
}
