import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WordService from "../../service/word/WordService";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const WORD_LENGTH = 5;

export default class HintsOverlay extends Component {
  constructor(props) {
    super(props);
    this.wordService = new WordService();
  }

  state = {
    possibleWords: [],
    hints: [],
    puzzleKey: null,
    solution: [],
  };

  componentDidMount() {
    const { puzzleKey, solution } = this.props;
    const possibleWords = this.wordService.getPossibleWords(
      puzzleKey,
      solution
    );
    const hints = this.getHints(possibleWords);
    this.setState({ puzzleKey, solution, possibleWords, hints });
  }

  getHints = (possibleWords) => {
    const hintMap = {};

    for (let word of possibleWords) {
      for (let i = 0; i < WORD_LENGTH - 2; i++) {
        let hint = "";
        for (let j = 0; j < i; j++) {
          hint += "_ ";
        }
        hint += word
          .slice(i, i + 2)
          .split("")
          .join(" ");
        hint += " ";
        for (let j = i + 2; j < WORD_LENGTH; j++) {
          hint += "_ ";
        }
        hintMap[hint] = (hintMap[hint] || 0) + 1;
      }
    }

    const hints = Object.entries(hintMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, Math.min(possibleWords.length, 5))
      .map((hint) => hint[0]);

    return hints;
  };

  render() {
    const { toggleShowHints } = this.props;

    const { solution, possibleWords, hints } = this.state;

    return (
      <div className="overlay">
        <div className="overlay-content">
          <FontAwesomeIcon
            icon={faXmark}
            className="icon xmark"
            onClick={toggleShowHints}
          />

          {solution.length === 5 && (
            <>
              <h2 style={{ fontSize: "20px", lineHeight: "40px" }}>
                puzzle is solved!
                <br />
                come back for hints
                <br /> to another puzzle
              </h2>
            </>
          )}

          {solution.length < 5 && (
            <h2 style={{ fontSize: "20px", lineHeight: "40px" }}>
              there {possibleWords.length === 1 ? "is" : "are"}{" "}
              {possibleWords.length === 0 ? "no" : possibleWords.length} word
              {possibleWords.length === 1 ? "" : "s"}
              <br />
              leading to a solution
            </h2>
          )}

          {hints.length > 0 && <h2 style={{ fontSize: "20px" }}>including:</h2>}

          {hints.map((hint, index) => {
            return (
              <h2 style={{ fontSize: "20px" }} key={index}>
                {hint.toUpperCase()}
              </h2>
            );
          })}
        </div>
      </div>
    );
  }
}
