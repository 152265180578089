import React, { Component } from "react";
import { faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

import CacheService from "./../../service/cache/CacheService";
import DateService from "./../../service/date/DateService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PuzzleService from "./../../service/puzzle/PuzzleService";

export default class PuzzlesOverlay extends Component {
  constructor(props) {
    super(props);
    this.puzzleService = new PuzzleService();
    this.dateService = new DateService();
    this.cacheService = new CacheService();
  }

  state = {
    puzzles: [],
    idx: 0,
    gameState: {},
  };

  componentDidMount() {
    const { puzzleKey } = this.props;
    const day = this.dateService.getDayOfYear();
    let puzzles = this.puzzleService.puzzles.slice(0, day + 1);
    const idx = this.puzzleService.getPuzzleIndex(puzzleKey);
    const gameState = this.cacheService.getGameState();
    this.setState({ puzzles, idx, gameState });
  }

  render() {
    const { toggleShowPuzzles, initForDay } = this.props;

    const { puzzles, gameState, idx } = this.state;

    return (
      <div className="overlay">
        <div className="overlay-content">
          <FontAwesomeIcon
            icon={faXmark}
            className="icon xmark"
            onClick={toggleShowPuzzles}
          />
          <h2 style={{ fontSize: "20px" }}>{puzzles.length} puzzles</h2>
          <div style={{ overflow: "scroll", paddingBottom: "20px" }}>
            {puzzles.map((puzzleKey, index) => {
              const puzzle = this.puzzleService.getPuzzleFromKey(puzzleKey);
              return (
                <div
                  id={puzzleKey}
                  key={index}
                  style={{
                    color:
                      gameState[puzzleKey] && gameState[puzzleKey].length === 5
                        ? "green"
                        : "var(--text-primary)",
                    cursor: "pointer",
                  }}
                  onClick={() => initForDay(index)}
                >
                  <h2 style={{ color: "var(--text-primary)" }}>
                    #{index}{" "}
                    {gameState[puzzleKey] &&
                      gameState[puzzleKey].length === 5 && (
                        <FontAwesomeIcon icon={faCircleCheck} color="green" />
                      )}
                  </h2>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      letterSpacing: 10,
                      fontWeight: index === idx ? "bold" : "",
                    }}
                  >
                    {puzzle.slice(0, 5).join("").toUpperCase()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      letterSpacing: 10,
                      fontWeight: index === this.state.idx ? "bold" : "",
                    }}
                  >
                    {puzzle.slice(5, 10).join("").toUpperCase()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      letterSpacing: 10,
                      fontWeight: index === this.state.idx ? "bold" : "",
                    }}
                  >
                    {puzzle.slice(10, 15).join("").toUpperCase()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      letterSpacing: 10,
                      fontWeight: index === this.state.idx ? "bold" : "",
                    }}
                  >
                    {puzzle.slice(15, 20).join("").toUpperCase()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      letterSpacing: 10,
                      fontWeight: index === this.state.idx ? "bold" : "",
                    }}
                  >
                    {puzzle.slice(20, 25).join("").toUpperCase()}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
