import React, { Component } from "react";
import {
  faBars,
  faChartSimple,
  faCircleQuestion,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import StatsService from "../service/stats/StatsService";

export default class Header extends Component {
  // constructor(props) {
  //   super(props);
  // this.statsService = new StatsService();
  // }

  componentDidMount() {
    // const { streak } = this.statsService.getStats();
    // this.setState({ streak });
  }

  state = {
    streak: 0,
  };

  render() {
    const {
      toggleShowHelp,
      toggleShowHints,
      toggleShowSettings,
      toggleShowStats,
      goToDailyPuzzle,
    } = this.props;

    return (
      <header>
        <div className="menu-left">
          <FontAwesomeIcon
            icon={faBars}
            className="icon"
            onClick={toggleShowSettings}
            style={{ padding: "8px" }}
          />
          <FontAwesomeIcon
            icon={faWandMagicSparkles}
            className="icon"
            onClick={toggleShowHints}
            style={{ padding: "8px" }}
          />
        </div>
        <div className="title" onClick={goToDailyPuzzle}>
          WordLock
        </div>
        <div className="menu-right">
          {/* <FontAwesomeIcon
            icon={faFire}
            className="icon"
            onClick={toggleShowStats}
          /> */}
          {/* <span className="streak-text">{this.state.streak}</span> */}
          <FontAwesomeIcon
            icon={faChartSimple}
            className="icon"
            onClick={toggleShowStats}
            style={{ padding: "8px" }}
          />
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="icon"
            onClick={toggleShowHelp}
            style={{ padding: "8px" }}
          />
        </div>
      </header>
    );
  }
}
