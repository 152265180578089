import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function MenuOverlay(props) {
  const {
    toggleShowMenu,
    toggleShowStats,
    toggleShowSettings,
    toggleShowContact,
  } = props;

  return (
    <div className="overlay">
      <div className="overlay-content">
        <FontAwesomeIcon
          icon={faXmark}
          className="icon xmark"
          onClick={toggleShowMenu}
        />
        <h2
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={toggleShowStats}
        >
          stats
        </h2>
        <h2
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={toggleShowSettings}
        >
          settings
        </h2>
        <h2
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={toggleShowContact}
        >
          contact
        </h2>
      </div>
    </div>
  );
}
