const START_YEAR = 2022;
const START_MONTH = 5;
const START_DAY = 1;

class DateService {
  getDayOfYear = () => {
    const now = new Date();
    const start = new Date(START_YEAR, START_MONTH, START_DAY);
    const diff =
      now -
      start +
      (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
  };

  getDateFromIndex = (index) => {
    const startDate = new Date(START_YEAR, START_MONTH, START_DAY);
    const date = startDate.setDate(startDate.getDate() + index);
    return date;
  };

  getDateYYYYMMDD = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  };
}

export default DateService;
